import React from "react";
import { Link } from "gatsby";

// components
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";

interface Props {
  name: string;
  id: string;
}

const PackCard = (props: Props): JSX.Element => {
  return (
    <Card variant="outlined">
      <CardActionArea style={{ height: "100%" }}>
        <CardContent>
          <p>{props.name}</p>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default PackCard;
